<template>
    <section class="container mx-auto">
        <!---
        <t-boxed>
            <template v-slot:body>
                <form @submit="trackk">
                    <h1 class="text-2xl text-black w-full py-3 ">
                        Trackk your package
                    </h1>
                    <div class="text-xs px-4 w-full text-red-700 mb-2" v-if="err">
                        {{ err }}
                    </div>
                    <div class="flex w-full md:justify-start justify-center mb-4">
                        <input class="bg-white rounded mr-4 border border-gray-400 focus:outline-none focus:border-pink-500 text-base px-4 w-full" placeholder="Tracking Number" type="text" name="tracking_number" v-model="trackingNumber" aria-label="Tracking Number" />
                        <button type="submit" :class="
              `inline-flex text-white bg-pink-500 border-0 py-2 px-2 w-10 focus:outline-none hover:bg-pink-600 rounded text-lg ${firstBTN}`
            " aria-label="Track NOW!">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-8 h-8 text-white  rounded-full" viewBox="0 0 20 20">
                                <path d="M18.125,15.804l-4.038-4.037c0.675-1.079,1.012-2.308,1.01-3.534C15.089,4.62,12.199,1.75,8.584,1.75C4.815,1.75,1.982,4.726,2,8.286c0.021,3.577,2.908,6.549,6.578,6.549c1.241,0,2.417-0.347,3.44-0.985l4.032,4.026c0.167,0.166,0.43,0.166,0.596,0l1.479-1.478C18.292,16.234,18.292,15.968,18.125,15.804 M8.578,13.99c-3.198,0-5.716-2.593-5.733-5.71c-0.017-3.084,2.438-5.686,5.74-5.686c3.197,0,5.625,2.493,5.64,5.624C14.242,11.548,11.621,13.99,8.578,13.99 M16.349,16.981l-3.637-3.635c0.131-0.11,0.721-0.695,0.876-0.884l3.642,3.639L16.349,16.981z"></path>
                            </svg>
                        </button>
                    </div>
                    <div v-if="carriers.length > 1" class="flex w-full md:justify-start justify-center mb-4">
                        <select class="bg-gray-100 rounded mr-4 border border-gray-400 focus:outline-none focus:border-pink-500 text-base px-4 w-full" placeholder="Tracking Number" type="text" name="tracking_number" v-model="carrierSelection">
                            <option value="null">Select your carrier</option>
                            <option v-for="carrier in carriers" v-bind:key="carrier.slug" :value="carrier.slug">{{ carrier.name }}</option>
                        </select>
                        <button type="submit" class="inline-flex text-white bg-pink-500 border-0 py-2 px-2 w-10 focus:outline-none hover:bg-pink-600 rounded text-lg">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-8 h-8 text-white  rounded-full" viewBox="0 0 20 20">
                                <path d="M18.125,15.804l-4.038-4.037c0.675-1.079,1.012-2.308,1.01-3.534C15.089,4.62,12.199,1.75,8.584,1.75C4.815,1.75,1.982,4.726,2,8.286c0.021,3.577,2.908,6.549,6.578,6.549c1.241,0,2.417-0.347,3.44-0.985l4.032,4.026c0.167,0.166,0.43,0.166,0.596,0l1.479-1.478C18.292,16.234,18.292,15.968,18.125,15.804 M8.578,13.99c-3.198,0-5.716-2.593-5.733-5.71c-0.017-3.084,2.438-5.686,5.74-5.686c3.197,0,5.625,2.493,5.64,5.624C14.242,11.548,11.621,13.99,8.578,13.99 M16.349,16.981l-3.637-3.635c0.131-0.11,0.721-0.695,0.876-0.884l3.642,3.639L16.349,16.981z"></path>
                            </svg>
                        </button>
                    </div>
                </form>
            </template>
        </t-boxed>-->
        <AppAd />
        <t-boxed>
            <template v-slot:body>
                <div class="flex flex-wrap  items-center">
                    <div class="w-full lg:w-6/12 px-4 mb-8">
                        <h2 class="text-md py-8  font-extrabold tracking-tight text-gray-900">
                            <span class="text-2xl text-gray-700 w-full py-3 ">
                                <span class="text-pink-700">trackk</span>.net
                                services
                            </span>
                        </h2>
                        <h2 class="text-md   font-extrabold tracking-tight text-gray-900">
                            Fast Tracking: Track all your packages
                        </h2>
                        <ul class="list-disc px-6">
                            <li>Unlimited Tracking Numbers</li>
                            <li>Multiple carriers (and more coming soon)</li>
                            <li>Auto-detect carriers</li>
                            <li>Batch tracking numbers import</li>
                            <li>
                                Barcode and QR code scanner for tracking numbers
                            </li>
                            <li>
                                Copy and share tracking links & results easily
                            </li>
                        </ul>
                        <br />
                        <h2 class="text-md   font-extrabold tracking-tight text-gray-900">
                            Numbers management / Parcel details
                        </h2>
                        <ul class="list-disc px-6">
                            <li>Archive or rename to your tracking numbers</li>
                            <li>Geolocation when possible</li>
                            <li>Parcel history / checkpoints</li>
                        </ul>
                        <br />
                        <h2 class="text-md   font-extrabold tracking-tight text-gray-900">
                            Push notifications
                        </h2>
                        <p class=" text-gray-700 w-full block  ">
                            Automatic notifications of parcel status update:
                        </p>
                        <ul class="list-disc px-6">
                            <li>
                                Pending / Supported: Parcel is recognizeed by
                                carrier service and we're waiting for data
                            </li>
                            <li>
                                Transit: Parcel has shipped from origin and is
                                in route to destination
                            </li>
                            <li>
                                Delivery: Parcel is out for delivery or at local
                                facility
                            </li>
                            <li>
                                Failed Attempt: Parcel was attempted for
                                delivery but failed
                            </li>
                            <li>
                                Exception: Something unusual has happend to your
                                parcel
                            </li>
                            <li>
                                Expired: Parcel was in transportation too long
                                without any delivery
                            </li>
                            <li>
                                Delivery: Parcel has arrived to its destination
                                successfully (and is now archived)
                            </li>
                        </ul>
                    </div>
                    <div class="w-10/12 md:w-4/12 lg:w-4/12 px-12 md:px-4 py-4 mr-auto ml-auto  hidden lg:block">
                        P
                        <img alt="..." src="@/assets/screenDetails.png" class="w-full align-middle " />
                    </div>
                </div>
            </template>
        </t-boxed>
        <!--<Carriers />-->
    </section>
</template>
<script>
//import Carriers from "@/views/Carriers.vue";
import AppAd from "@/components/AppAd.vue";

export default {
    components: {
        //Carriers,
        AppAd
    },
    data() {
        return {
            trackingNumber: "",
            err: false,
            carriers: [],
            carrierSelection: "null",
            posted: false
        };
    },
    title() {
        return `Track all your packages - ${this.$title}`
    },
    methods: {
        reset() {
            this.carriers = [];
            this.carrierSelection = "null";
            this.err = false;
            this.posted = false;
        },
        trackk(evt) {
            evt.preventDefault();
            if (this.carrierSelection == "null") {
                this.$axios.get(`${process.env.VUE_APP_API}/carriers`).then(response => { this.carriers = response.data })

            } else {
                this.$router.push(
                    `/t/${this.carrierSelection}/${this.trackingNumber}`
                );
                this.reset()
            }
        },
    },
    computed: {
        appScreen() {
            return require(`@/assets/app.png`).default;
        },
        notifs() {
            return require(`@/assets/notifs.png`).default;
        },
        screenDetails() {
            return require(`@/assets/screenDetails.png`).default;
        },
        firstBTN() {
            if (this.carriers.length <= 1) {
                return "";
            }
            return "opacity-0";
        }
    },
};
</script>
<template>
	<div>
		<navbar-component></navbar-component>
		<main class="pb-20 -mt-8 bg-hero-1 px-2 trackk-content" style="">
			<router-view />
		</main>
		<footer-component></footer-component>
	</div>
</template>
<script>
import NavbarComponent from "@/components/Navbar.vue";
import FooterComponent from "@/components/Footer.vue";

export default {
	components: {
		NavbarComponent,
		FooterComponent,
	},
	computed: {
		isAuthenticated() {
			return this.$store.getters.isAuthenticated;
		},
	},
};
</script>

<template>
    <section class="text-gray-700  mhc body-font">
        <div class="container py-2 px-2  mx-auto w-full">
            <div class="bg-gray-200 shadow overflow-hidden rounded">
                <div class="text-2xl text-center px-6 w-full py-6 ">
                    Dead Link (404) <br />
                    <img :src="require(`@/assets/404.png`).default" class="inline-block py-6" />
                </div>
            </div>
        </div>
    </section>
</template>
import { createWebHistory, createRouter } from "vue-router";

import Default from "@/layouts/Default.vue";

import Landing from "./views/Landing.vue";
import Privacy from "./views/Privacy.vue";
import Contact from "./views/Contact.vue";
import Err404 from "./views/404.vue";
import Terms from "./views/Terms.vue";

const routes = [{
        path: "/admin/",
        component: () =>
            import(
                /* webpackChunkName: "admin" */
                "./layouts/Admin.vue"
            ),
        children: [{
                path: "/admin/",
                component: () =>
                    import(
                        /* webpackChunkName: "admin" */
                        "./views/admin/Index.vue"
                    ),
            },
            {
                path: "/admin/users",
                component: () =>
                    import(
                        /* webpackChunkName: "admin" */
                        "./views/admin/Clients.vue"
                    ),
            },
            {
                path: "/admin/purchases",
                component: () =>
                    import(
                        /* webpackChunkName: "admin" */
                        "./views/admin/Purchases.vue"
                    ),
            },
            {
                path: "/admin/clients/:id",
                component: () =>
                    import(
                        /* webpackChunkName: "admin" */
                        "./views/admin/Client.vue"
                    ),
            },
            {
                path: "/admin/tracks",
                component: () =>
                    import(
                        /* webpackChunkName: "admin" */
                        "./views/admin/Tracks.vue"
                    ),
            },
        ],
    },
    {
        path: "/",
        component: Default,
        children: [{
                path: "/",
                component: Landing,
            },
            {
                path: "/404",
                component: Err404,
            },

            {
                path: "/privacy-policy",
                component: Privacy,
            },
             {
                path: "/terms",
                component: Terms,
            },
            {
                path: "/contact",
                component: Contact,
            },
            {
                path: "/map",
                name: "Map",
                component: () =>
                    import(
                        /* webpackChunkName: "track" */
                        "./views/Map.vue"
                    ),
            },
            {
                path: "/carriers/",
                name: "Carriers",
                component: () =>
                    import(
                        /* webpackChunkName: "track" */
                        "./views/Carriers.vue"
                    ),
            },
            {
                path: "/p/:id/",
                name: "Track Result",
                component: () =>
                    import(
                        /* webpackChunkName: "track" */
                        "./views/TrackResult.vue"
                    ),
            },
            {
                path: "/t/:carrier/:tracking_number",
                name: "TrackV2",
                component: () =>
                    import(
                        /* webpackChunkName: "track" */
                        "./views/TrackV2.vue"
                    ),
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,

    routes,
});

router.beforeEach((to, from, next) => {
    if (!to.matched.length) {
        next("/404");
    } else {
        next();
    }
});

export default router;
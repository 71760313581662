<template>
    <table class="border-collapse w-full">
        <thead>
            <tr>
                <th v-for="(title, property) in headers" v-bind:key="property" class="p-2 font-bold uppercase bg-pink-600 text-sm text-gray-200 squared border border-pink-700 hidden md:table-cell">
                    {{ title }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-bind:key="item.id" v-for="(item, index) in data" :class="`flex md:table-row flex-row md:flex-row flex-wrap md:flex-no-wrap mb-10 md:mb-0 ${bgColor(index)} border-b md:border-0 border-pink-500`">
                <td v-for="(title, property) in headers" v-bind:key="`${property}_${item.id}`" class="w-full border-0 md:border-b border-pink-500  md:w-auto py-3 px-0 md:px-3 text-gray-700 text-md text-left md:text-center block md:table-cell relative md:static ">
                    <div class="w-full md:hidden  bg-pink-700 text-gray-200 px-2 py-1 text-xs font-bold uppercase mb-2">{{ title }}</div>
                    <slot :name="`${property}_item`" v-bind:data="{ prop: item[property], id: item.id, item: item }">
                        {{ item[property] }}
                    </slot>
                </td>
            </tr>
        </tbody>
    </table>
</template>
<script>
//import { EventBus } from "@/helpers/utils";

export default {
    name: "DataTable",
    props: {
        headers: {
            type: Object,
            default () {
                return {};
            }
        },
        data: {
            type: Array,
            default () {
                return [];
            }
        }
    },
    methods: {
        bgColor(i) {
            if (i % 2 == 0) {
                return 'bg-gray-200'
            }
            return 'bg-white'
        }
    }
};
</script>
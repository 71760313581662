<template>
    <div class="container mx-auto px-4 bg-white shadow rounded my-4">
        <div class="container px-2 py-4 mx-auto flex sm:flex-nowrap flex-wrap">
            <div class="w-full bg-white rounded-lg overflow-hidden sm:mr-6 p-4 flex items-start justify-start relative">
                <div class="w-full ">
                    <div class="h-auto ">
                        <h1 class="text-2xl text-black w-full py-3 ">
                            Contact-us
                        </h1>
                        <a class="text-2xl text-black w-full py-3 " href="javascript:void(0);" @click="
                                this.linkTo_UnCryptMailto(
                                    'nbjmup;sfrvftuAusbdll/ofu'
                                )
                            ">request [at] trackk [dot] net</a>
                    </div>
                    <form @submit="contact" v-if="!sent" class="mt-4">
                        <p class="mt-2 mb-4 text-gray-600">
                            <b class="text-black">Fullname</b>
                            <input v-model="fullname" type="text" placeholder="Your fullname" class="px-3 py-3 placeholder-gray-600 border-gray-500 text-gray-800 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full border" />
                        </p>
                        <p class="mt-2 mb-4 text-gray-600">
                            <b class="text-black">E-Mail</b>
                            <input v-model="email" type="text" placeholder="Your e-mail address" class="px-3 py-3 placeholder-gray-600 border-gray-500 text-gray-800 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full border" />
                        </p>
                        <div class="mt-2 mb-4 text-gray-600">
                            <b class="text-black">Subject</b>
                            <select class="px-3 py-3 placeholder-gray-600 border-gray-500 text-gray-800 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full border" v-model="subject" placeholder="Email">
                                <option value="Empty">Select Subject</option>
                                <option value="NewCarrier">Add a new carrier</option>
                                <option value="Bug">Bug</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                        <div class="mt-2 mb-4 text-gray-600">
                            <b class="text-black">Message</b>
                            <textarea class="px-3 py-3 placeholder-gray-600 border-gray-500 text-gray-800 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full border" placeholder="Message" v-model="message"></textarea>
                        </div>
                        <button class="bg-pink-600 text-white active:bg-pink-800 text-sm font-bold uppercase px-4 py-3 rounded shadow hover:shadow-md outline-none focus:outline-none mb-3" type="submit">
                            Send message
                        </button>
                        <span class="text-pink-600 text-xs pl-2 font-bold block" v-if="error != false">Incomplete form or unkwown error. You can contact
                            us directly: &nbsp;
                            <i class="fas fa-envelope fa" />
                            <b class="text-blue-700">{{ $email }}</b>&nbsp;&nbsp; <i class="fas fa-phone fa" />
                            <b class="text-blue-700">{{ $phone }}</b>
                        </span>
                    </form>
                    <div v-else>
                        <p class="leading-relaxed mt-4">
                            <b>Thank you for contacting us</b><br />
                            Your message has been sent to our support team. All
                            information received will always remain
                            confidential. We will contact you as soon as we
                            review your message.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            fullname: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
            sent: false,
            error: false,
        };
    },
    title() {
        return `Contact - ${this.$title}`;
    },
    methods: {
        UnCryptMailto(s) {
            var n = 0;
            var r = "";
            for (var i = 0; i < s.length; i++) {
                n = s.charCodeAt(i);
                if (n >= 8364) {
                    n = 128;
                }
                r += String.fromCharCode(n - 1);
            }
            return r;
        },
        sendContact(data) {
            return this.$axios.post(
                `${process.env.VUE_APP_API}/public/contact`,
                data
            );
        },
        linkTo_UnCryptMailto(s) {
            location.href = this.UnCryptMailto(s);
        },
        contact(evt) {
            evt.preventDefault();

            this.sendContact({
                fullname: this.fullname,
                email: this.email,
                subject: this.subject,
                message: this.message,
            }).then((response) => {
                if (response.data == true) {
                    this.sent = true;
                    this.error = false;
                } else {
                    this.error = true;
                }
            });
        },
    },
};
</script>
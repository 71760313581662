<template>
  <footer class="text-gray-700 body-font">
    <div class="container shadow border-t"></div>

    <div class="bg-gray-200">
      <div class="container px-5 py-6 mx-auto ">
        <p class="text-sm text-gray-700 sm:ml-6 sm:mt-0  ">
          © 2020 <span class="text-pink-700">trackk</span>.net -
          <span class="hidden md:inline"
            >by
            <a
              href="https://twitter.com/un_geek"
              class="text-pink-800 hover:text-gray-900 "
              target="_blank"
              rel="noreferrer"
              >@un_geek</a
            >
            - </span
          >
          <router-link to="/privacy-policy" class="text-gray-700 hover:text-gray-800"
            >Privacy Policy</router-link
          > - 
          <router-link to="/terms" class="text-gray-700 hover:text-gray-800"
            >Terms of Service</router-link
          >
        </p>
      </div>
    </div>
  </footer>
</template>

<template>
    <ul class="flex list-reset  w-auto float-right my-3" v-if="items.pages > 1">
        <li v-if="items.has_prev">
            <a class="block hover:text-white hover:bg-pink-500 border-r border-pink-700  text-gray-700 px-3 py-2" href="javascript:void(0);" @click="prev()">Previous</a>
        </li>
        <li v-bind:key="i" v-for="i in items.pages">
            <a v-if="i != items.page" class="block hover:text-white hover:bg-pink-500  text-gray-700 border-pink-700 border-r  px-3 py-2" href="javascript:void(0);" @click="page(i)">{{ i }}</a>
            <a v-else class="block font-bold border-pink-700  hover:text-white text-gray-100 bg-pink-700 border-r  px-3 py-2" href="javascript:void(0);">{{ i }}</a>
        </li>
        <li v-if="items.has_next">
            <a class="block hover:text-white text-gray-700 hover:bg-pink-500 px-3 py-2" href="javascript:void(0);" @click="next()">Next</a>
        </li>
    </ul>
</template>
<script>
export default {
    name: "Paginate",
    props: {
        items: {
            type: Object,
            default () {
                return {};
            }
        }
    },
    methods: {
        next() {
            this.$eventBus.emit("goToPage", this.items.page + 1);
        },
        prev() {
            this.$eventBus.emit("goToPage", this.items.page - 1);
        },
        page(page) {
            this.$eventBus.emit("goToPage", page);
        }
    }
};
</script>
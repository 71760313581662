import { createStore } from 'vuex'
import { isValidJwt, getUserFromJwt, authenticate } from "@/helpers/utils";
//import EventBus from "@/helpers/event-bus";
import Cookie from 'cookie-universal'

const cookie = Cookie()
const store = createStore({
    state: {
        user: getUserFromJwt(localStorage.getItem("userToken")),
        jwt: localStorage.getItem("userToken") || false,
        sideBarOpen: false,
    },
    getters: {
        sideBarOpen: (state) => {
            return state.sideBarOpen;
        },
        isAuthenticated: state => {
            if (isValidJwt(state.jwt)) return true;

            localStorage.removeItem("userToken");
            return false;
        },
        user: state => {
            return state.user;
        }
    },
    mutations: {
        setJwtToken(state, payload) {
            localStorage.userToken = payload.jwt.access_token;
            state.jwt = payload.jwt.access_token;
            state.user = getUserFromJwt(payload.jwt.access_token)
            cookie.set('auth-user', payload.jwt.access_token, {
                path: '/',
                maxAge: 60 * 60 * 24 * 7
            })
        },
        clearAll(state) {
            state.jwt = null
            state.user = null
            cookie.remove('auth-user')
        },
                toggleSidebar(state) {
            state.sideBarOpen = !state.sideBarOpen
        },
    },
    actions: {
        toggleSidebar(context) {
            context.commit('toggleSidebar')
        },
        loginForce(context, data) {
            context.commit("setJwtToken", { jwt: { token: data } });
        },
        login(context, userData) {
            return authenticate(userData)
                .then(response => {
                    console.log(response.data)
                    context.commit("setJwtToken", { jwt: response.data });
                    return true
                })
                .catch(() => {
                    localStorage.removeItem("userToken");
                    //EventBus.emit("failedAuthentication", error.response);
                    return false
                });
        },
        logout(context) {
            localStorage.removeItem("userToken");
            context.commit("clearAll")

        }
    }
});

export default store;
import { createApp } from "vue";
import axios from "axios";

import "@fortawesome/fontawesome-free/css/all.min.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import EventBus from "@/helpers/event-bus";
import VCalendar from 'v-calendar';
import titleMixin from "@/helpers/title-mixin";
import VueGtag from "vue-gtag-next";


import "@/assets/styles/tailwind.css";


const app = createApp(App);
app.use(router);
app.use(store)
app.use(VCalendar)
//app.use(GoogleMap, { apiKey: 'AIzaSyB_ZgqjP2wEDXTTRqwM_KKQqOqVDxPGR4s' })


app.use(VueGtag, {
  property: {
    id: "GTM-WHF6HT8",
  }
});

var dayjs = require("dayjs");

import Boxed from "./components/Boxed.vue";
app.component('t-boxed', Boxed)
app.component('ts-boxed', Boxed)
import Paginate from "./components/Paginate.vue";
app.component('ts-paginate', Paginate)
import DataTable from "./components/DataTable.vue";
app.component('ts-table', DataTable)
import Tag from "./components/Tag.vue";
app.component('tag', Tag)

import "./registerServiceWorker";
app.mixin(titleMixin)
app.config.globalProperties.$eventBus = EventBus

app.config.globalProperties.$dayjs = dayjs;
app.config.globalProperties.$titleShort = "trackk";

app.config.globalProperties.$title = "trackk.net";
app.config.globalProperties.$axios = axios

app.config.globalProperties.$desc = "Track All your packages";
app.config.globalProperties.$filters = {
    fromNow(value) {
        return dayjs(value).format("DD/MM/YY @ h:mm A");
    },
    fromNowSimple(value) {
        return dayjs(value).format("DD/MM/YY");
    },
};


app.directive("click-outside", {
    bind: function(el, binding, vnode) {
        el.clickOutsideEvent = function(event) {
            // here I check that click was outside the el and his children
            if (!(el == event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
    },
    unbind: function(el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
    }
});



app.mount("#app");

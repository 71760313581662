<template>
    <div>
        <section class="container   mx-auto mhc">
            <t-boxed>
                <template v-slot:body>
                    <div class="content-text" section-language="en">
                        <div id="md_en" data-target="out_en" class="agreement_md">
                            <h2>End-User License Agreement (EULA) of <span class="app_name">Trackk</span></h2>
                            <p>This End-User License Agreement ("EULA") is a legal agreement between you and <span class="company_name">Florian Gasquez</span> (hereinafter - Trackk) .</p>
                            <p>This EULA agreement governs your acquisition and use of our <span class="app_name">Trackk</span> software ("Software") directly from <span class="company_name">Trackk</span> or indirectly through a <span class="company_name">Trackk</span> authorized reseller or distributor (a "Reseller"). Our Privacy Policy was created by <a href="https://www.generateprivacypolicy.com/">the Privacy Policy Generator</a>.</p>
                            <p>Please read this EULA agreement carefully before completing the installation process and using the <span class="app_name">Trackk</span> software. It provides a license to use the <span class="app_name">Trackk</span> software and contains warranty information and liability disclaimers.</p>
                            <p>If you register for a free trial of the <span class="app_name">Trackk</span> software, this EULA agreement will also govern that trial. By clicking "accept" or installing and/or using the <span class="app_name">Trackk</span> software, you are confirming your acceptance of the Software and agreeing to become bound by the terms of this EULA agreement.</p>
                            <p>If you are entering into this EULA agreement on behalf of a company or other legal entity, you represent that you have the authority to bind such entity and its affiliates to these terms and conditions. If you do not have such authority or if you do not agree with the terms and conditions of this EULA agreement, do not install or use the Software, and you must not accept this EULA agreement.</p>
                            <p>This EULA agreement shall apply only to the Software supplied by <span class="company_name">Trackk</span> herewith regardless of whether other software is referred to or described herein. The terms also apply to any <span class="company_name">Trackk</span> updates, supplements, Internet-based services, and support services for the Software, unless other terms accompany those items on delivery. If so, those terms apply.</p>
                            <h3>License Grant</h3>
                            <p><span class="company_name">Trackk</span> hereby grants you a personal, non-transferable, non-exclusive licence to use the <span class="app_name">Trackk</span> software on your devices in accordance with the terms of this EULA agreement.</p>
                            <p>You are permitted to load the <span class="app_name">Trackk</span> software (for example a PC, laptop, mobile or tablet) under your control. You are responsible for ensuring your device meets the minimum requirements of the <span class="app_name">Trackk</span> software.</p>
                            <p>You are not permitted to:</p>
                            <ul>
                                <li>Edit, alter, modify, adapt, translate or otherwise change the whole or any part of the Software nor permit the whole or any part of the Software to be combined with or become incorporated in any other software, nor decompile, disassemble or reverse engineer the Software or attempt to do any such things</li>
                                <li>Reproduce, copy, distribute, resell or otherwise use the Software for any commercial purpose</li>
                                <li>Allow any third party to use the Software on behalf of or for the benefit of any third party</li>
                                <li>Use the Software in any way which breaches any applicable local, national or international law</li>
                                <li>use the Software for any purpose that <span class="company_name">Trackk</span> considers is a breach of this EULA agreement</li>
                            </ul>
                            <h3>Intellectual Property and Ownership</h3>
                            <p><span class="company_name">Trackk</span> shall at all times retain ownership of the Software as originally downloaded by you and all subsequent downloads of the Software by you. The Software (and the copyright, and other intellectual property rights of whatever nature in the Software, including any modifications made thereto) are and shall remain the property of <span class="company_name">Trackk</span>.</p>
                            <p><span class="company_name">Trackk</span> reserves the right to grant licences to use the Software to third parties.</p>
                            <h3>Termination</h3>
                            <p>This EULA agreement is effective from the date you first use the Software and shall continue until terminated. You may terminate it at any time upon written notice to <span class="company_name">Trackk</span>.</p>
                            <p>It will also terminate immediately if you fail to comply with any term of this EULA agreement. Upon such termination, the licenses granted by this EULA agreement will immediately terminate and you agree to stop all access and use of the Software. The provisions that by their nature continue and survive will survive any termination of this EULA agreement.</p>
                            <h3>Governing Law</h3>
                            <p>This EULA agreement, and any dispute arising out of or in connection with this EULA agreement, shall be governed by and construed in accordance with the laws of <span class="country">France</span>.</p>
                            <h1>END USER LICENSE AGREEMENT</h1>
                            <p>This Agreement governs the relationship between the Administration of the site "trackk.net" (hereinafter - the Administration of the site) and the User of this Site.</p>
                            <p>
                                The Administration of the site reserves the right at any time to change, add or delete clauses of this Agreement without notifying the User. The user is personally responsible for verifying this Agreement for any changes to it.</p>
                            <p>Use of the Site by the User means acceptance of the Agreement and the changes made to this Agreement.</p>
                            <h2>Auto Renewing Subscriptions</h2>
                            <ul>
                                <li>User can purchase Premium auto renewing subscription for 1 month or 1 year to disable ads, enable Push Notifications and support app development</li>
                                <li>Payment will be charged to Google Play account at confirmation of purchase</li>
                                <li>Subscription automatically renews unless auto-renew is turned off at least 24-hours before the end of the current period</li>
                                <li>Account will be charged for renewal within 24-hours prior to the end of the current period. Cost of renewal stays the same as when you first purchased subscription.</li>
                                <li>You can manage your subscription or turn off auto-renewal by going to the your Goole Play Account Settings after purchase</li>
                            </ul>
                            <h2>A responsibility</h2>
                            <p>Any losses that the User may suffer in the event of intentional or reckless violation of any provision of this Agreement, as well as due to unauthorized access to the communications of another User, are not reimbursed by the Administration of the site.</p>
                            <p>The site administration is not responsible for:</p>
                            <ul>
                                <li>Delays or malfunctions in the process of the operation occurring due to force majeure, as well as any case of malfunctions in telecommunication, computer, electrical and other related systems.</li>
                                <li>Actions of transfer systems, banks, payment systems and for delays associated with their work.</li>
                                <li>The proper functioning of the Site, if the User does not have the necessary technical means for its use, and also does not bear any obligations to provide users with such means.</li>
                            </ul>
                        </div>
                    </div>
                </template></t-boxed>
        </section>
    </div>
</template>
<script>
export default {
    metaInfo() {
        return {
            title: "Terms of Use"
        };
    }
};
</script>
import EventBus from "@/helpers/event-bus";

function getTitle (vm) {
  // components can simply provide a `title` option
  // which can be either a string or a function
  const { title } = vm.$options
  if (title) {
    return typeof title === 'function'
      ? title.call(vm)
      : title
  }
}


const clientTitleMixin = {
  mounted () {
    const title = getTitle(this)
    if (title) {
      document.title = title
    }
    EventBus.on('newTitle', (t) => {
      document.title = t
    })
  }
}

// `VUE_ENV` can be injected with `webpack.DefinePlugin`
export default clientTitleMixin
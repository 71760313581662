<template>
    <nav class="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow">
        <div class="container px-4 mx-auto flex flex-wrap items-center justify-between">
            <div class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start ">
                <router-link to="/" class="flex items-center" :title="$title">
                    <img :src="logo" :alt="$title" class="flex -col w-8 h-8 mr-2 " />
                    <div class=" font-bold text-pink-700 flex-col text-2xl ">
                        <span class="text-pink-700 text-base font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap   ">{{ $titleShort }}<span class="text-gray-700">.net</span></span>
                    </div>
                </router-link>
                <button class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none" type="button" v-on:click="toggleNavbar">
                    <i class="fas fa-bars"></i>
                </button>
            </div>
            <div class="lg:flex flex-grow items-center" :class="[showMenu ? 'block' : 'hidden']">
                <div class=" font-bold text-pink-700 flex-col text-2xl ">
                    <span class="text-pink-700 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase mt-4 block md:hidden ">{{ $titleShort }}</span>
                </div>
                <ul class="flex flex-col lg:flex-row list-none mr-auto">
                    <li class="flex items-center">
                        <router-link :class="[this.$route.path == '/' ? 'text-pink-700' : 'text-gray-800']" class="hover:text-pinkGray-500  px-3 py-2 flex items-center text-xs uppercase font-bold" to="/">
                            <i class="text-pinkGray-400 fas fa-box-open text-lg leading-lg mr-2" />
                            Services
                        </router-link>
                    </li>
                   <li class="flex items-center">
                        <router-link :class="[this.$route.path.includes('carriers') ? 'text-pink-700' : 'text-gray-800']" class="hover:text-pinkGray-500  px-3 py-2 flex items-center text-xs uppercase font-bold" to="/carriers">
                            <i class="text-pinkGray-400 fas fa-truck text-lg leading-lg mr-2" />
                            Carriers
                        </router-link>
                    </li>
                     <li class="flex items-center">
                        <router-link :class="[this.$route.path.includes('map') ? 'text-pink-700' : 'text-gray-800']" class="hover:text-pinkGray-500  px-3 py-2 flex items-center text-xs uppercase font-bold" to="/map">
                            <i class="text-pinkGray-400 fas fa-map-marked-alt text-lg leading-lg mr-2" />
                            Trackk Map
                        </router-link>
                    </li>
                </ul>
                <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">
                    <li class="flex items-center">
                        <router-link class="bg-indigo-600 text-white active:bg-indigo-800 text-sm font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3" to="/contact" style="transition: all 0.15s ease 0s;">
                            <i class="fas fa-file-signature"></i> Contact
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>
<script>
export default {
    data() {
        return {
            showMenu: false,
        };
    },
    methods: {
        toggleNavbar: function() {
            this.showMenu = !this.showMenu;
        },
    },
    computed: {
        logo() {
            return require(`@/assets/favicon-32x32.png`).default;
        },
    },
};
</script>
import axios from "axios";

export function isValidJwt(jwt) {
  if (!jwt || jwt.split(".").length < 3) {
    return false;
  }
  const data = JSON.parse(atob(jwt.split(".")[1]));
  const exp = new Date(data.exp * 1000);
  const now = new Date();
  return now < exp;
}

export function getUserFromJwt(jwt) {
  if (!jwt || jwt.split(".").length < 3) {
    return false;
  }
  const data = JSON.parse(atob(jwt.split(".")[1]));
  return data.user;
}

export function authenticate(userData) {
  return axios.post(`${process.env.VUE_APP_API}/user/auth`, userData);
}
<template>
  <div :class="bgClass">{{text}}</div>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
    },
    text: {
      type: String
    },
    isXl: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      colors: {
        support: {
          'connected': 'bg-green-600',
          'disconnected': 'bg-orange-600',
        },
        truefalse: {
          'true': 'bg-green-600',
          'false': 'bg-orange-600',
        },
        payment: {
          'confirmed': 'bg-green-600',
          'refunded': 'bg-orange-600',
          'chargeback': 'bg-red-600',
          'failed': 'bg-pink-600'
        },
        subscription: {
          'active': 'bg-green-600',
          'canceled': 'bg-orange-600',
          'trialing': 'bg-pink-600',
          'incomplete': 'bg-red-600',
          'incomplete_expired': 'bg-red-600',
          'past_due': 'bg-red-600',
          'unpaid': 'bg-red-600',
        },
        tracking: {
          'unknown': 'border-2 bg-white text-orange-700 border-orange-600',
          'pending': 'border-2 bg-white text-indigo-700 border-indigo-600',
          'transit': 'border-2 bg-white text-blue-700 border-blue-600',
          'supported': 'border-2 bg-white text-blue-700 border-blue-600',
          'delivery': 'border-2 bg-white text-green-700 border-green-600',
          'failed_attempt': 'border-2 bg-white text-red-700 border-red-500',
          'exception': 'border-2 bg-white text-red-700 border-red-700',
          'expired': 'border-2 bg-white text-red-700 border-red-700',
          'delivered': 'border-2 bg-white text-green-800 border-green-800',
          'not_found': 'border-2 bg-white text-orange-700 border-orange-700',
        }
      }
    }
  },
  computed: {
    bgClass() {
      if (this.text == undefined)
        return ''

      let isXl = 'text-xs'
      if (this.isXl)
        isXl = 'text-md'
      let color = this.colors[this.type][this.text.toLowerCase()];
      if (color == undefined || color == null) {
        color = 'bg-gray-800'
      }
      return `${isXl} inline-block rounded  font-semibold text-white m-0 py-1 px-2 ${color}`
    }
  }
}

</script>